import Swal from 'sweetalert2';

function initSmsSettingsModal() {
  $('#smsSettingsModal').on('show.bs.modal', function () {
    $('#loaderContainer').show();
    $('#errorContainer').hide();
    $('#smsSettingsContent').hide();
    fetchSmsStatus();
  });

  function fetchSmsStatus() {
    const customerId = $('#customer_id').val();
    $.ajax({
      url: `/features/sapaad_online/sms_status/${customerId}`,
      type: 'GET',
      dataType: 'json',
      success: function(data) {
        if (data && data.sms_enabled !== undefined) {
          updateSmsModal(data.sms_enabled);
          $('#errorContainer').hide();
        } else {
          showError('Unexpected response format. Failed to fetch SMS status.');
        }
      },
      error: function(xhr) {
        const response = xhr.responseJSON;
        const errorMessage = response && response.message ? response.message : 'Failed to fetch SMS status.';
        showError(errorMessage);
      },
      complete: function() {
        $('#loaderContainer').hide();
      }
    });
  }

  function updateSmsModal(isEnabled) {
    const toggleSwitch = $('#toggleOnlineSms');
    toggleSwitch.prop('checked', isEnabled);
    $('#smsSettingsContent').show();
  }

  function showError(message) {
    const retryLink = '<a href="#" id="retryLink">Please try again</a>';
    $('#errorMessage').html(`${message} ${retryLink}`);
    $('#errorContainer').show();
    $('#smsSettingsContent').hide();
    $('#loaderContainer').hide();
  }

  $(document).on('click', '#retryLink', function(e) {
    e.preventDefault();
    $('#errorContainer').hide();
    $('#loaderContainer').show();
    fetchSmsStatus();
  });

  window.toggleOnlineSms = function() {
    const toggleSwitch = $('#toggleOnlineSms');
    const isSmsEnabled = toggleSwitch.prop('checked');
    const customerId = $('#customer_id').val();
    const action = isSmsEnabled ? 'enable' : 'disable';
    const confirmationText = isSmsEnabled ? 'You are about to enable SMS.' : 'You are about to disable SMS.';
    const buttonText = isSmsEnabled ? 'Enable SMS' : 'Disable SMS';
    const initialCheckedState = toggleSwitch.prop('checked');

    Swal.fire({
      title: 'Are you sure?',
      type: "question",
      text: confirmationText,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: buttonText,
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        $('#loaderContainer').show();
        $('#smsSettingsContent').hide();

        $.ajax({
          url: `/features/sapaad_online/sms_status/${customerId}/${action}`,
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
          success: function(data) {
            if (data.success) {
              Swal.fire('Success!', `SMS has been ${isSmsEnabled ? 'enabled' : 'disabled'}.`, 'success');
              $('#smsSettingsContent').show();
              $('#errorContainer').hide();
            } else {
              showError(data.message || 'There was an issue updating the SMS status.');
            }
          },
          error: function(xhr) {
            const response = xhr.responseJSON;
            const errorMessage = response && response.message ? response.message : 'Something went wrong. Please try again later.';
            Swal.fire('Error!', errorMessage, 'error');
            showError(errorMessage);
          },
          complete: function() {
            $('#loaderContainer').hide();
          }
        });
      } else {
        toggleSwitch.prop('checked', !initialCheckedState);
      }
    });
  };
}

// Ensure the modal initialization runs on both page load and when navigating via Turbolinks
$(document).ready(initSmsSettingsModal);
document.addEventListener('turbolinks:load', initSmsSettingsModal);
